import React from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import Image from 'gatsby-image';
import parse from "html-react-parser"
import { useStaticQuery, graphql } from "gatsby"

export default function ProcessPage() {

  const { allWpPage: {edges}, wpPage: {id, slug, content, title} } = useStaticQuery(graphql`
  query ProcessQuery {
    allWpPage(filter: {parentId: {eq: "cG9zdDoxMDQ="}}) {
      edges {
        node {
          id
          title
          slug
          content
          uri
          featuredImage {
            node {
              id
              sourceUrl
              altText
            }
          }
        }
      }
    }
    wpPage(slug: {eq: "process"}) {
      id
      slug
      title
      content
    }
  }`)

  const getContent = (content) => {
    const startPharagraph = content.indexOf('<p>');
    const endPharagraph = content.indexOf('</p>');
    return parse(content.slice(startPharagraph, endPharagraph + 4));
  }
  
  const children = edges.map(x => x.node).map((card, i) => {
    const { featuredImage: {node} } = card;
    return (
      <Col
        key={i.toString()}
        md={8}
        xs={24}
      >
        <img src={node.sourceUrl} alt="" width="150" height="150" />
        <h5>{card.title}</h5>
        <div className="detail">{getContent(card.content)}</div>
      </Col>
    )
  }).reverse() || [];

  return (
    <OverPack component="section" className="page-wrapper page3 text-center">
      <QueueAnim
        type="bottom"
        className="page"
        leaveReverse
        key="page3"
      >
        <h2 key="title">{parse(title)}</h2>
        <span key="line" className="separator" />
        <QueueAnim component={Row} type="bottom" key="content">
          {children}
        </QueueAnim>
      </QueueAnim>
    </OverPack>);
}